const actions = {
  USERS_REQUEST: 'USERS_REQUEST',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_ERROR: 'USERS_ERROR',

  ROLES_REQUEST: 'ROLES_REQUEST',
  ROLES_SUCCESS: 'ROLES_SUCCESS',
  ROLES_ERROR: 'ROLES_ERROR',

  ENABLEUSER_REQUEST: 'ENABLEUSER_REQUEST',
  ENABLEUSER_SUCCESS: 'ENABLEUSER_SUCCESS',
  ENABLEUSER_ERROR: 'ENABLEUSER_ERROR',

  DISABLEUSER_REQUEST: 'DISABLEUSER_REQUEST',
  DISABLEUSER_SUCCESS: 'DISABLEUSER_SUCCESS',
  DISABLEUSER_ERROR: 'DISABLEUSER_ERROR',

  DELETEUSER_REQUEST: 'DELETEUSER_REQUEST',
  DELETEUSER_SUCCESS: 'DELETEUSER_SUCCESS',
  DELETEUSER_ERROR: 'DELETEUSER_ERROR',

  UPDATE_USERS_FILTER: 'UPDATE_USERS_FILTER',
  UPDATE_USERS_FILTER_FIELD: 'UPDATE_USERS_FILTER_FIELD',
  RESET_USERS_FILTER_PAGE: 'RESET_USERS_FILTER_PAGE',

  getUsers: (filter, page, sort) => ({
    type: actions.USERS_REQUEST,
    payload: { filter, page, sort },
  }),

  getAllRoles: () => ({ type: actions.ROLES_REQUEST }),

  enableUser: (id) => ({
    type: actions.ENABLEUSER_REQUEST,
    payload: { id },
  }),

  disableUser: (id) => ({
    type: actions.DISABLEUSER_REQUEST,
    payload: { id },
  }),

  deleteUser: (id) => ({
    type: actions.DELETEUSER_REQUEST,
    payload: { id },
  }),

  updateUsersFilter: (filter) => ({
    type: actions.UPDATE_USERS_FILTER,
    payload: { filter },
  }),

  updateUsersFilterField: (field, value) => ({
    type: actions.UPDATE_USERS_FILTER_FIELD,
    payload: { field, value },
  }),

  resetUsersFilterPage: () => ({
    type: actions.RESET_USERS_FILTER_PAGE,
  }),
};
export default actions;

import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams } from '../helpers/authHelper';
import message from '../../helpers/message';

export function* getSlots() {
  yield takeEvery(actions.SLOTS_REQUEST, function* ({ payload }) {
    const { from, to, hallId } = payload;
    yield authorizedCallParams(
      api.getSlots,
      payload,
      (data) => ({
        type: actions.SLOTS_SUCCESS,
        slots: data,
        hallId,
        from,
        to,
      }),
      (err) => ({
        type: actions.SLOTS_ERROR,
        message: err,
      })
    );
  });
}

export function* createSlot() {
  yield takeEvery(actions.CREATESLOT_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.createSlot,
      payload,
      () => ({
        type: actions.CREATESLOT_SUCCESS,
      }),
      (err) => ({ type: actions.CREATESLOT_ERROR, message: err })
    );
  });
}

export function* editSlot() {
  yield takeEvery(actions.EDITSLOT_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.editSlot,
      payload,
      () => ({
        type: actions.EDITSLOT_SUCCESS,
      }),
      (err) => ({ type: actions.EDITSLOT_ERROR, message: err })
    );
  });
}

export function* deleteSlot() {
  yield takeEvery(actions.DELETESLOT_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.deleteSlot,
      payload,
      () => ({
        type: actions.DELETESLOT_SUCCESS,
      }),
      (err) => ({ type: actions.DELETESLOT_ERROR, message: err })
    );
  });
}

export function* deleteSlotAll() {
  yield takeEvery(actions.DELETESLOTALL_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.deleteSlotAll,
      payload,
      () => ({
        type: actions.DELETESLOTALL_SUCCESS,
      }),
      (err) => ({ type: actions.DELETESLOTALL_SUCCESS, message: err })
    );
  });
}


export function* onCreateSlotSuccess() {
  yield takeEvery(actions.CREATESLOT_SUCCESS, function* ({ payload }) {
    const { hallId, from, to } = yield select((state) => state.SlotEdit);
    yield put(actions.getSlots(hallId, from, to));
    message.success('Slot vytvořen');
  });
}

export function* onEditSlotSuccess() {
  yield takeEvery(actions.EDITSLOT_SUCCESS, function* ({ payload }) {
    const { hallId, from, to } = yield select((state) => state.SlotEdit);
    yield put(actions.getSlots(hallId, from, to));
    message.success('Slot upraven');
  });
}

export function* onDeleteSlotSuccess() {
  yield takeEvery(actions.DELETESLOT_SUCCESS, function* ({ payload }) {
    const { hallId, from, to } = yield select((state) => state.SlotEdit);
    yield put(actions.getSlots(hallId, from, to));
    message.success('Slot vymazán');
  });
}

export function* onDeleteSlotAllSuccess() {
  yield takeEvery(actions.DELETESLOTALL_SUCCESS, function* ({ payload }) {
    const { hallId, from, to } = yield select((state) => state.SlotEdit);
    yield put(actions.getSlots(hallId, from, to));
    message.success('Slot vymazán');
  });
}

export default function* rootSaga() {
  yield all([
    fork(getSlots),
    fork(createSlot),
    fork(editSlot),
    fork(deleteSlot),
    fork(deleteSlotAll),
    fork(onCreateSlotSuccess),
    fork(onEditSlotSuccess),
    fork(onDeleteSlotSuccess),
    fork(onDeleteSlotAllSuccess),
  ]);
}

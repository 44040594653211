import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import { authorizedCall, authorizedCallParams } from '../helpers/authHelper';
import actions from './actions';
import * as api from '../../api';

export function* getPortalConfigRequest() {
  yield takeEvery('PORTALCONFIG_REQUEST', function* () {
    yield authorizedCall(
      api.getPortalConfig,
      (data) => ({
        type: actions.PORTALCONFIG_SUCCESS,
        data: data,
      }),
      (err) => ({
        type: actions.PORTALCONFIG_ERROR,
        message: err,
      })
    );
  });
}

export default function* rootSaga() {
  yield all([fork(getPortalConfigRequest)]);
}

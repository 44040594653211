import actions from './actions';

const initState = { events: [], from: null, to: null, hallId: null };

export default function eventReducer(state = initState, action) {
  switch (action.type) {
    case actions.EVENT_SUCCESS:
      return {
        events: action.events,
        from: action.from,
        to: action.to,
        hallId: action.hallId,
      };
    case actions.EVENT_ERROR:
      return {
        ...initState,
        error: action.message,
      };
    default:
      return state;
  }
}

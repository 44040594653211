import uuid from '../utils/uuid';
import moment from 'moment';
//const serviceUri = 'http://app03.ee.adalo.cz:7989/api/v1/';
//const serviceUri = 'http://localhost:7989/api/v1/';
const serviceUri = 'https://obradni-sine.cz/api/v1/';

const defaults = {
  method: 'POST',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

class AdaloError extends Error {
  constructor(message, data) {
    super(message);
    this.data = data;
    this.name = 'AdaloError';
  }
}

const convertResponse = (response) =>
  response
    .json()
    .then((json) => json)
    .catch((e) => response);
//   try {
//     const json =
//   } catch {
//     return response;
//   }
// };

const isString = (value) =>
  typeof value === 'string' || value instanceof String;

const defaultPOST = { ...defaults, method: 'POST' };
const defaultGET = { ...defaults, method: 'GET' };

const authorized = (authKey, data) => ({
  ...data,
  headers: { ...data.headers, 'x-AuthKey': authKey },
});

const CreateRequest = (method, path, authKey, body) => {
  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('Content-Type', 'application/json');
  if (authKey) myHeaders.append('X-AuthKey', authKey);

  const request = new Request(serviceUri + path, {
    method: method,
    headers: myHeaders,
    mode: 'cors',
    body,
  });
  console.log(request);
  if (body) console.log(body);
  return request;
};

export const checkResult = (data) => {
  console.log(data);

  if (data.status && data.status != 200 && !isString(data.status)) {
    let errortext = `${data.message || data.statusText || data.status}`;
    if (errortext.includes('attempting to fetch')) {
      errortext = 'Server není dostupný, prosím zkuste to později';
    }
    throw new AdaloError(errortext, data);
  }
};

//todo: check reponse and convert json

export const loginUser = ({ username, password }) => {
  return fetch(
    CreateRequest(
      'POST',
      'login',
      null,
      JSON.stringify({
        password: password,
        login: username,
      })
    )
  )
    .then(convertResponse)
    .then((responseJson) => {
      console.log(responseJson);
      checkResult(responseJson);
      return responseJson;
    });
};

export const logout = ({ authKey }) => {
  return fetch(CreateRequest('GET', 'logout', authKey))
    .then((response) => {
      return response;
    })
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const authinfo = ({ authKey }) => {
  return fetch(CreateRequest('GET', 'authinfo', authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

// {
//     "days": [
//       {
//         "day": "yyyy-MM-dd'T'HH:mm:ss'Z'",
//         "timeSlots": [
//           {
//             "end": "yyyy-MM-dd'T'HH:mm:ss'Z'",
//             "start": "yyyy-MM-dd'T'HH:mm:ss'Z'",
//             "status": "FREE"
//           }
//         ]
//       }
//     ],
//     "end": "yyyy-MM-dd'T'HH:mm:ss'Z'",
//     "start": "yyyy-MM-dd'T'HH:mm:ss'Z'"
//   }

const statusMap = {
  FREE: { text: 'Volno', color: '#4482FF' },
  RESERVED: { text: 'Obsazeno', color: 'red' },
};

const getStatus = (key) => {
  try {
    return statusMap[key];
  } catch {
    return { text: 'neznamo', color: 'black' };
  }
};

const timeFormat = (date) => moment(date).format('HH:mm');

export const getEvents = ({ authKey, hallId, from, to }) => {
  return fetch(
    CreateRequest(
      'POST',
      `ceremonyHall/${hallId}/slots`,
      authKey,
      JSON.stringify({ start: from, end: to })
    )
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data.days != null
        ? data.days.reduce(
            (acc, cur) => [
              ...cur.timeSlots.map((t) => ({
                ...t,
                title: getStatus(t.status).text,
                desc: timeFormat(t.start) + '-' + timeFormat(t.end),
                color: getStatus(t.status).color,
              })),
              ...acc,
            ],
            []
          )
        : [];
    });
};

export const registerEvent = ({ authKey, data }) => {
  return fetch(
    CreateRequest('POST', `booking/book`, authKey, JSON.stringify(data))
  ).then((data) => checkResult(data));
};

export const getCeremonyHalls = ({ authKey }) => {
  return fetch(CreateRequest('GET', 'ceremonyHallsOverview', authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const getUsers = ({ authKey, filter, page, sort }) => {
  const requestBody = { filter, page, sort };
  return fetch(
    CreateRequest(
      'POST',
      `user/find-users`,
      authKey,
      JSON.stringify(requestBody)
    )
  )
    .then((response) => response.json())
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const getAllRoles = ({ authKey }) => {
  return fetch(CreateRequest('GET', `user/role-list`, authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data.roles;
    });
};

export const enableUser = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `user/enable/${id}`, authKey))
    .then(convertResponse)
    .then((response) => {
      checkResult(response);
    });
};

export const disableUser = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `user/disable/${id}`, authKey))
    .then(convertResponse)
    .then((response) => {
      checkResult(response);
    });
};

export const deleteUser = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `user/remove/${id}`, authKey))
    .then(convertResponse)
    .then((response) => {
      checkResult(response);
    });
};

export const getUser = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `user/find-id/${id}`, authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const changePassword = ({
  authKey,
  oldPassword,
  newPassword,
  checkNewPassword,
}) => {
  return fetch(
    CreateRequest(
      'POST',
      `user/change-own-password`,
      authKey,
      JSON.stringify({ oldPassword, newPassword, checkNewPassword })
    )
  )
    .then((response) => {
      return response.status != 200 ? response.json() : response;
    })
    .then((data) => {
      checkResult(data);
      return true;
    }); /*.catch(e => console.log(e))*/
};

export const createUser = ({ authKey, user }) => {
  return fetch(
    CreateRequest('POST', `user/create`, authKey, JSON.stringify(user))
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const editUser = ({ authKey, user }) => {
  return fetch(
    CreateRequest(
      'POST',
      `user/modify/${user.id}`,
      authKey,
      JSON.stringify(user)
    )
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const getBookingOverview = ({ authKey, filter, page, sort }) => {
  const requestBody = { filter, page, sort };
  return fetch(
    CreateRequest(
      'POST',
      `booking/overview`,
      authKey,
      JSON.stringify(requestBody)
    )
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const getBookingInfo = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `booking/by-id/${id}`, authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const cancelBooking = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `booking/cancel/${id}`, authKey))
    .then(convertResponse)
    .then((response) => {
      checkResult(response);
    });
};

export const confirmBooking = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `booking/confirm/${id}`, authKey))
    .then(convertResponse)
    .then((response) => {
      checkResult(response);
    });
};

export const getHall = ({ authKey, id }) => {
  return fetch(CreateRequest('GET', `ceremonyHall/by-id/${id}`, authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const deleteHall = ({ authKey, id }) => {
  // return new Promise((resolve) => {
  //   console.log("delete hall: " + id);
  //   resolve();
  // });

  return fetch(CreateRequest('DELETE', `ceremonyHall/delete/${id}`, authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const editHall = ({ authKey, id, data }) => {
  return fetch(
    CreateRequest(
      'POST',
      `ceremonyHall/modify/${id}`,
      authKey,
      JSON.stringify(data)
    )
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const createHall = ({ authKey, data }) => {
  console.log('create');
  return fetch(
    CreateRequest('POST', `ceremonyHall/create`, authKey, JSON.stringify(data))
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const getSlots = ({ authKey, hallId, from, to }) => {
  return fetch(
    CreateRequest(
      'POST',
      `ceremonyHall/${hallId}/slots`,
      authKey,
      JSON.stringify({ start: from, end: to })
    )
  )
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data.days != null
        ? data.days.reduce(
            (acc, cur) => [
              ...cur.timeSlots.map((t) => ({
                ...t,
                color: getStatus(t.status).color,
              })),
              ...acc,
            ],
            []
          )
        : [];
    });
};

export const createSlot = ({ authKey, hallId, data }) => {
  let postdata = null;
  console.log(data);
  if (data.slotType=='SINGLE') {
    postdata = {
      id: null,
      slotType: data.slotType,
      timeFrom: data.timeRange[0],
      timeTo: data.timeRange[1],
      start: data.date,
      end: data.date,
      status: null
    }
  }

  if (data.slotType=='RECURRENT') {
    postdata = {
      id: null,
      slotType: data.slotType,
      timeFrom: data.timeRange[0],
      timeTo: data.timeRange[1],
      start: data.dateRange[0],
      end: data.dateRange[1],
      status: null
    }
  }

  console.log("create slot", postdata);

  return fetch(
    CreateRequest('POST', `ceremonyHall/createslot/${hallId}`, authKey, JSON.stringify(postdata))
  )
    .then(convertResponse)
    .then((data) => checkResult(data));
};

export const editSlot = ({ authKey, data }) => {
  console.log("edit slot", data);

  return fetch(
    CreateRequest('POST', `slot/modify`, authKey, JSON.stringify(data))
  )
    .then(convertResponse)
    .then((data) => checkResult(data));
};

export const deleteSlot = ({ authKey, data, start }) => {
  return fetch(CreateRequest('DELETE', `ceremonyHall/slots/${data}`, authKey, JSON.stringify(start) ))
    .then(convertResponse)
    .then((data) => checkResult(data));
};

export const deleteSlotAll = ({ authKey, data, start }) => {
  return fetch(CreateRequest('DELETE', `ceremonyHall/slotsallnext/${data}`, authKey, JSON.stringify(start) ))
    .then(convertResponse)
    .then((data) => checkResult(data));
};

export const getPortalConfig = ({ authKey }) => {
  return fetch(CreateRequest('GET', `portal/config`, authKey))
    .then(convertResponse)
    .then((data) => {
      checkResult(data);
      return data;
    });
};

export const getBookingPdfFile = ({ authKey, id }) => {
    console.log("authkey = ", authKey, " id = ", id);
    return new Promise((resolve, reject) => {
        var request = new XMLHttpRequest();
        request.open('GET', serviceUri +`booking/by-id-pdf/${id}`, true);
        request.setRequestHeader('X-AuthKey', authKey);
        request.responseType = 'blob';
        request.onload = function () {
            resolve(request.response);/*
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
                resolve(e.target.result);
            };*/
        };
        request.send();
    });

    /*return fetch(CreateRequest('GET', `booking/by-id-pdf/${id}`, authKey))
        .then((data) => {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            console.log(data);
            //checkResult(data);
            return data;
        });*/
};

import actions from './actions';

const initState = { data: {} };

export default function eventReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_HALL_SUCCESS:
      return {
        data: action.data,
        error: null,
      };
    case actions.CLEAR_HALL_DATA:
      return {
        ...initState,
        error: null,
      };
    case actions.GET_HALL_ERROR:
      return {
        ...state,
        error: action.message,
      };
    default:
      return state;
  }
}

const actions = {
  GET_HALL_REQUEST: 'GET_HALL_REQUEST',
  GET_HALL_SUCCESS: 'GET_HALL_SUCCESS',
  GET_HALL_ERROR: 'GET_HALL_ERROR',

  getHall: (id) => ({
    type: actions.GET_HALL_REQUEST,
    payload: { id },
  }),

  EDIT_HALL_REQUEST: 'EDIT_HALL_REQUEST',
  EDIT_HALL_SUCCESS: 'EDIT_HALL_SUCCESS',
  EDIT_HALL_ERROR: 'EDIT_HALL_ERROR',

  editHall: (id, data, history) => ({
    type: actions.EDIT_HALL_REQUEST,
    payload: { id, data },
    history,
  }),

  CREATE_HALL_REQUEST: 'CREATE_HALL_REQUEST',
  CREATE_HALL_SUCCESS: 'CREATE_HALL_SUCCESS',
  CREATE_HALL_ERROR: 'CREATE_HALL_ERROR',

  createHall: (data, history) => ({
    type: actions.CREATE_HALL_REQUEST,
    payload: { data },
    history,
  }),

  CLEAR_HALL_DATA: 'CLEAR_HALL_DATA',
  clearHallData: () => ({ type: actions.CLEAR_HALL_DATA }),
};
export default actions;

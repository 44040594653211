import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from './auth/reducer'; // '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Halls from './halls/reducer';
import HallCalendar from './hallCalendar/reducer';
import Users from './users/reducer';
import User from './user/reducer';
import UserSettings from './userSettings/reducer';
import BookingOverview from './bookingOverview/reducer';
import BookingInfo from './bookingInfo/reducer';
import HallEdit from './hallEdit/reducer';
import SlotEdit from './timeSlotsEdit/reducer';
import PortalConfig from './portalConfig/reducer';

export default combineReducers({
  Auth,
  App,
  BookingInfo,
  BookingOverview,
  Halls,
  HallCalendar,
  HallEdit,
  SlotEdit,
  PortalConfig,
  Users,
  User,
  UserSettings,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
});

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  MOREUSERINFO_SUCCESS: 'MOREUSERINFO_SUCCESS',
  MOREUSERINFO_ERROR: 'MOREUSERINFO_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  // login: (token = false) => ({
  //   type: actions.LOGIN_REQUEST,
  //   payload: { token },
  // }),
  login: (username, password, wantPersist) => ({
    type: actions.LOGIN_REQUEST,
    payload: { username, password, wantPersist },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;

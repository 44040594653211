import actions from './actions';

const defaultBooking = {};

const initState = { data: defaultBooking, isVisible: false };

export default function bookingReducer(state = initState, action) {
  switch (action.type) {
    case actions.BOOKINGINFO_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    case actions.BOOKINGINFO_ERROR:
      return {
        ...state,
        get_error: action.message,
      };

    case actions.SHOW_BOOKINGINFO_MODAL:
      return {
        ...state,
        isVisible: action.payload.show,
        data: defaultBooking,
      };

    default:
      return state;
  }
}

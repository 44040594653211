const actions = {
  BOOKINGOVERVIEW_REQUEST: 'BOOKINGOVERVIEW_REQUEST',
  BOOKINGOVERVIEW_SUCCESS: 'BOOKINGOVERVIEW_SUCCESS',
  BOOKINGOVERVIEW_ERROR: 'BOOKINGOVERVIEW_ERROR',

  CONFIRMBOOKING_REQUEST: 'CONFIRMBOOKING_REQUEST',
  CONFIRMBOOKING_SUCCESS: 'CONFIRMBOOKING_SUCCESS',
  CONFIRMBOOKING_ERROR: 'CONFIRMBOOKING_ERROR',

  STORNOBOOKING_REQUEST: 'STORNOBOOKING_REQUEST',
  STORNOBOOKING_SUCCESS: 'STORNOBOOKING_SUCCESS',
  STORNOBOOKING_ERROR: 'STORNOBOOKING_ERROR',

  UPDATE_BOOKINGOVERVIEW_FILTER: 'UPDATE_BOOKINGOVERVIEW_FILTER',
  UPDATE_BOOKINGOVERVIEW_FILTER_FIELD: 'UPDATE_BOOKINGOVERVIEW_FILTER_FIELD',
  RESET_BOOKINGOVERVIEW_FILTER_PAGE: 'RESET_BOOKINGOVERVIEW_FILTER_PAGE',

  RESET_BOOKINGOVERVIEW_FILTER: 'RESET_BOOKINGOVERVIEW_FILTER',

  getBookingOverview: (filter, page, sort) => ({
    type: actions.BOOKINGOVERVIEW_REQUEST,
    payload: { filter, page, sort },
  }),

  confirmBooking: (id) => ({
    type: actions.CONFIRMBOOKING_REQUEST,
    payload: { id },
  }),

  stornoBooking: (id) => ({
    type: actions.STORNOBOOKING_REQUEST,
    payload: { id },
  }),

  updateFilter: (filter) => ({
    type: actions.UPDATE_BOOKINGOVERVIEW_FILTER,
    payload: { filter },
  }),

  updateFilterField: (field, value) => ({
    type: actions.UPDATE_BOOKINGOVERVIEW_FILTER_FIELD,
    payload: { field, value },
  }),

  resetFilterPage: () => ({
    type: actions.RESET_BOOKINGOVERVIEW_FILTER_PAGE,
  }),

  resetFilterState: () => ({ type: actions.RESET_BOOKINGOVERVIEW_FILTER }),
};
export default actions;

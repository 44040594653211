import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams, authorizedCall } from '../helpers/authHelper';

export function* getBookingInfo() {
  yield takeEvery(actions.BOOKINGINFO_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.getBookingInfo,
      payload,
      (data) => ({
        type: actions.BOOKINGINFO_SUCCESS,
        data: data,
      }),
      (err) => ({
        type: actions.BOOKINGINFO_ERROR,
        message: err,
      })
    );
  });
}

export default function* rootSaga() {
  yield all([fork(getBookingInfo)]);
}

import actions from './actions';
import { combineReducers } from 'redux';
import { createFormattedDateTimePartsComponent } from 'react-intl/src/components/createFormattedComponent';
import moment from 'moment';
import { stateOptions } from '../../containers/BookingOverview/status';
const initState = {
  items: [],
  page: 0,
  totalItems: 0,
  filter: defaultFilterState,
};

const data = (state = initState, action) => {
  switch (action.type) {
    case actions.BOOKINGOVERVIEW_SUCCESS:
      return {
        ...state,
        items: action.items,
        page: action.page,
        totalItems: action.totalItems,
      };
    case actions.BOOKINGOVERVIEW_ERROR:
      return {
        ...state,
        error: action.message,
      };

    default:
      return state;
  }
};

const defaultFilterState = {
  filter: {
    ceremonyHallId: null,
    from: moment(new Date().setHours(0, 0, 0, 0)),
    to: null,
    status: stateOptions.filter((o) => o.visibleInList).map((v) => v.value),
  },
  page: {
    page: 0,
    recordPerPage: 50,
  },
  sort: {
    asc: true,
    column: 'timeSlot',
  },
};

const filter = (state = defaultFilterState, action) => {
  switch (action.type) {
    case actions.UPDATE_BOOKINGOVERVIEW_FILTER_FIELD:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
    case actions.UPDATE_BOOKINGOVERVIEW_FILTER:
      return {
        ...state,
        ...action.payload.filter,
      };
    case actions.RESET_BOOKINGOVERVIEW_FILTER:
      return {
        ...state,
        ...defaultFilterState,
      };
    case actions.RESET_BOOKINGOVERVIEW_FILTER_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          page: 0,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  filter,
});

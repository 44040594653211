const actions = {
  EVENT_REQUEST: 'EVENT_REQUEST',
  EVENT_SUCCESS: 'EVENT_SUCCESS',
  EVENT_ERROR: 'EVENT_ERROR',
  REGISTER_EVENT_REQUEST: 'REGISTER_EVENT_REQUEST',
  REGISTER_EVENT_SUCCESS: 'REGISTER_EVENT_SUCCESS',
  REGISTER_EVENT_ERROR: 'REGISTER_EVENT_ERROR',
  getEvents: (hallId, from, to) => ({
    type: actions.EVENT_REQUEST,
    payload: { hallId, from, to },
  }),
  registerEvent: (data) => ({
    type: actions.REGISTER_EVENT_REQUEST,
    payload: { data, data },
  }),
};
export default actions;

import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams } from '../helpers/authHelper';
import message from '../../helpers/message';

// export function* getEvents() {
//   yield takeEvery(actions.EVENT_REQUEST, function* ({ payload }) {
//     const { from, to, hallId } = payload;
//     try {
//       const token = getToken().get('idToken');
//       if (token) {
//         const data = yield call(api.getEvents, token, hallId, from, to);
//         yield put({
//           type: actions.EVENT_SUCCESS,
//           events: data,
//           hallId,
//           from,
//           to,
//         });
//       }
//     } catch (ex) {
//       yield put({ type: actions.EVENT_ERROR, message: ex.message });
//     }
//   });
// }

export function* getEvents() {
  yield takeEvery(actions.EVENT_REQUEST, function* ({ payload }) {
    const { from, to, hallId } = payload;
    yield authorizedCallParams(
      api.getEvents,
      payload,
      (data) => ({
        type: actions.EVENT_SUCCESS,
        events: data,
        hallId,
        from,
        to,
      }),
      (err) => ({
        type: actions.EVENT_ERROR,
        message: err,
      })
    );
  });
}

export function* registerEvent() {
  yield takeEvery(actions.REGISTER_EVENT_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.registerEvent,
      payload,
      () => ({
        type: actions.REGISTER_EVENT_SUCCESS,
      }),
      (err) => ({ type: actions.REGISTER_EVENT_ERROR, message: err })
    );
  });
}

export function* registerEventSuccess() {
  yield takeEvery(actions.REGISTER_EVENT_SUCCESS, function* ({ payload }) {
    const { hallId, from, to } = yield select((state) => state.HallCalendar);
    yield put(actions.getEvents(hallId, from, to));
    message.success('Termín rezervován');
  });
}

export function* eventsError() {
  yield takeEvery(actions.EVENT_ERROR, function* () {});
}

export default function* rootSaga() {
  yield all([
    fork(getEvents),
    fork(registerEvent),
    fork(eventsError),
    fork(registerEventSuccess),
  ]);
}

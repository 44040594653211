import {
  all,
  takeEvery,
  put,
  fork,
  call,
  select,
  delay,
} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams } from '../helpers/authHelper';
import message from '../../helpers/message';
import { history } from '../root-saga';

// const history = createBrowserHistory();

export function* getHall() {
  yield takeEvery(actions.GET_HALL_REQUEST, function* ({ payload }) {
    const { from, to, hallId } = payload;
    yield authorizedCallParams(
      api.getHall,
      payload,
      (data) => ({
        type: actions.GET_HALL_SUCCESS,
        data: data,
      }),
      (err) => ({
        type: actions.GET_HALL_ERROR,
        message: err,
      })
    );
  });
}

export function* editHall() {
  yield takeEvery(actions.EDIT_HALL_REQUEST, function* ({ payload, history }) {
    const { id, data } = payload;
    yield authorizedCallParams(
      api.editHall,
      payload,
      (data) => ({
        type: actions.EDIT_HALL_SUCCESS,
        data: data,
        history,
      }),
      (err) => ({
        type: actions.EDIT_HALL_ERROR,
        message: err,
      })
    );
  });
}

export function* createHall() {
  yield takeEvery(actions.CREATE_HALL_REQUEST, function* ({
    payload,
    history,
  }) {
    yield authorizedCallParams(
      api.createHall,
      payload,
      (data) => ({
        type: actions.CREATE_HALL_SUCCESS,
        data: data,
        history,
      }),
      (err) => ({
        type: actions.CREATE_HALL_ERROR,
        message: err,
      })
    );
  });
}

export function* getHallError() {
  yield takeEvery(actions.GET_HALL_ERROR, function* () {});
}

export function* editHallSuccess() {
  yield takeEvery(actions.EDIT_HALL_SUCCESS, function* ({ data, history }) {
    // netusim proc nefunguje
    yield call(history.push, '../');
    message.success('Uloženo');
    // prozatimne
    // yield call(() => window.location.href = "/dashboard/hallsPage");
  });
}

export function* onCreateHallSuccess() {
  yield takeEvery(actions.CREATE_HALL_SUCCESS, function* ({ history }) {
    yield call(history.push, '../');
    message.success('Vytvořeno');
  });
}

export default function* rootSaga() {
  yield all([
    fork(getHall),
    fork(createHall),
    fork(getHallError),
    fork(editHall),
    fork(editHallSuccess),
    fork(onCreateHallSuccess),
  ]);
}

import { notification } from 'antd';

notification.config({
  duration: 6,
});

class Message {
  success(message, time) {
    notification.success({ message: message });
  }

  warning(message, time) {
    notification.warning({ message: message });
  }

  error(message, time) {
    notification.error({ message: message, duration: time || 10 });
  }
}

const message = new Message();

export default message;

import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
// import { createBrowserHistory } from 'history';
import { getToken, setToken, clearToken } from '../helpers/authHelper';
import actions from './actions';
import * as api from '../../api';
import { authorizedCall, authorizedCallParams } from '../helpers/authHelper';
import { history } from '../root-saga';

// export const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { username, password, wantPersist } = payload;
    try {
      const data = yield call(api.loginUser, { username, password });
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: data.authKey,
        userInfo: data,
        wantPersist: wantPersist,
      });
    } catch (ex) {
      yield put({ type: actions.LOGIN_ERROR, message: ex.message });
    }
  });
}

export function* userMoreInfoRequest() {
  yield takeEvery('LOGIN_SUCCESS', function* (payload) {
    const { userInfo } = payload;
    yield authorizedCallParams(
      api.getUser,
      { id: userInfo.userId },
      (data) => ({ type: actions.MOREUSERINFO_SUCCESS, data: data }),
      (err) => ({ type: actions.MOREUSERINFO_ERROR, message: err })
    );
  });
}
export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield setToken(payload.token, {
      wantPersist: payload.wantPersist,
    });
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield authorizedCall(
      api.logout,
      () => ({ type: actions.LOGOUT_SUCCESS }),
      (err) => ({ type: actions.LOGOUT_ERROR, message: err })
    );

    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    try {
      if (token) {
        const data = yield call(api.authinfo, { authKey: token });
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: data.authKey,
          userInfo: data,
        });
      }
    } catch (ex) {
      yield put({ type: actions.LOGIN_ERROR, message: ex.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(userMoreInfoRequest),
  ]);
}

import actions from './actions';
import { combineReducers } from 'redux';

const initState = { formError: [] };

function passwordReducer(state = initState, action) {
  switch (action.type) {
    case actions.CHANGEPASSWORD_ERROR:
      return {
        ...state,
        formError: action.data.items,
      };
    case actions.CHANGEPASSWORD_SUCCESS:
    case actions.CLEAR_ERROR_CHANGEPASSWORD:
      return { ...state, formError: [] };
    default:
      return state;
  }
}

export default combineReducers({
  password: passwordReducer,
});

import actions from './actions';
import { combineReducers } from 'redux';
import ActionButton from 'antd/lib/modal/ActionButton';

const initState = {
  items: [],
  page: 0,
  totalItems: 0,
  allRoles: [],
  filter: defaultFilterState,
};

const data = (state = initState, action) => {
  switch (action.type) {
    case actions.USERS_SUCCESS:
      return {
        ...state,
        items: action.items,
        page: action.page,
        totalItems: action.totalItems,
      };
    case actions.ROLES_SUCCESS:
      return {
        ...state,
        allRoles: action.roles,
      };
    case actions.ROLES_ERROR:
    case actions.USERS_ERROR:
      return {
        ...state,
        error: action.message,
      };

    default:
      return state;
  }
};

const defaultFilterState = {
  filter: {
    email: null,
    firstname: null,
    funeralServiceName: null,
    lastname: null,
    roleNames: [],
  },
  page: {
    page: 0,
    recordPerPage: 50,
  },
  sort: {
    asc: true,
    column: 'lastname',
  },
};

const filter = (state = defaultFilterState, action) => {
  switch (action.type) {
    case actions.UPDATE_USERS_FILTER_FIELD:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
    case actions.UPDATE_USERS_FILTER:
      return {
        ...state,
        ...action.payload.filter,
      };
    case actions.RESET_USERS_FILTER_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          page: 0,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  filter,
});

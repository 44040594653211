import actions from './actions';

const initState = { list: [] };

export default function hallsReducer(state = initState, action) {
  switch (action.type) {
    case actions.HALLS_SUCCESS:
      return {
        list: action.halls,
      };
    case actions.HALLS_ERROR:
      return state;
    default:
      return state;
  }
}

import actions from './actions';

const initState = { idToken: null, userInfo: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        userInfo: action.userInfo,
      };
    case actions.LOGOUT:
      return initState;
    case actions.LOGIN_ERROR:
      return {
        ...initState,
        error: action.message,
      };
    case actions.MOREUSERINFO_SUCCESS: {
      return { ...state, userInfo: { ...state.userInfo, more: action.data } };
    }
    default:
      return state;
  }
}

const actions = {
  BOOKINGINFO_REQUEST: 'BOOKINGINFO_REQUEST',
  BOOKINGINFO_SUCCESS: 'BOOKINGINFO_SUCCESS',
  BOOKINGINFO_ERROR: 'BOOKINGINFO_ERROR',
  getBookingInfo: (id) => ({
    type: actions.BOOKINGINFO_REQUEST,
    payload: { id },
  }),
  SHOW_BOOKINGINFO_MODAL: 'SHOW_BOOKINGINFO_MODAL',
  showBookingInfoModal: (show) => ({
    type: actions.SHOW_BOOKINGINFO_MODAL,
    payload: { show },
  }),
};
export default actions;

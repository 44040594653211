import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams, authorizedCall } from '../helpers/authHelper';
import message from '../../helpers/message';

export function* changePassword() {
  yield takeEvery(actions.CHANGEPASSWORD_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.changePassword,
      payload,
      (data) => {
        return {
          type: actions.CHANGEPASSWORD_SUCCESS,
        };
      },
      (err, data) => ({
        type: actions.CHANGEPASSWORD_ERROR,
        message: err,
        data: data,
      })
    );
  });
}

export function* changePasswordSuccess() {
  yield takeEvery(actions.CHANGEPASSWORD_SUCCESS, function* ({ payload }) {
    message.success('Heslo změněno');
  });
}

export default function* rootSaga() {
  yield all([fork(changePassword), fork(changePasswordSuccess)]);
}

import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams, authorizedCall } from '../helpers/authHelper';
import message from '../../helpers/message';

export function* getUser() {
  yield takeEvery(actions.USER_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.getUser,
      payload,
      (data) => ({
        type: actions.USER_SUCCESS,
        data: data,
      }),
      (err) => ({
        type: actions.USER_ERROR,
        message: err,
      })
    );
  });
}

export function* createUser() {
  yield takeEvery(actions.CREATEUSER_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.createUser,
      payload,
      (data) => ({
        type: actions.CREATEUSER_SUCCESS,
        data: data,
      }),
      (err, fullError) => {
        return {
          type: actions.CREATEUSER_ERROR,
          message: err,
          error: fullError,
        };
      }
    );
  });
}

export function* editUser() {
  yield takeEvery(actions.EDITUSER_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.editUser,
      payload,
      (data) => ({
        type: actions.EDITUSER_SUCCESS,
        data: data,
      }),
      (err, fullError) => {
        return {
          type: actions.EDITUSER_ERROR,
          message: err,
          error: fullError,
        };
      }
    );
  });
}

export default function* rootSaga() {
  yield all([fork(getUser), fork(createUser), fork(editUser)]);
}

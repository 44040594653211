import actions from './actions';

const initState = { data: {}, isLoading: false, isLoaded: false };

export default function portalConfigReducer(state = initState, action) {
  switch (action.type) {
    case actions.PORTALCONFIG_REQUEST:
      return { ...state.data, isLoading: true };
    case actions.PORTALCONFIG_SUCCESS:
      return {
        data: action.data,
        isLoading: false,
        isLoaded: true,
      };
    case actions.PORTALCONFIG_ERROR:
      return { ...state, isLoaded: false, isLoading: false };
    default:
      return state;
  }
}

const actions = {
  HALLS_REQUEST: 'HALLS_REQUEST',
  HALLS_SUCCESS: 'HALLS_SUCCESS',
  HALLS_ERROR: 'HALLS_ERROR',
  getHalls: () => ({
    type: actions.HALLS_REQUEST,
  }),
  DELETE_HALL_REQUEST: 'DELETE_HALL_REQUEST',
  DELETE_HALL_SUCCESS: 'DELETE_HALL_SUCCESS',
  DELETE_HALL_ERROR: 'DELETE_HALL_ERROR',

  deleteHall: (id) => ({
    type: actions.DELETE_HALL_REQUEST,
    payload: { id },
  }),
};
export default actions;

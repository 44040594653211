export const status = {
  UNKNOWN: 'UNKNOWN',
  WAIT_FOR_CONFIRM: 'WAIT_FOR_CONFIRM',
  CONFIRM: 'CONFIRM',
  CANCEL: 'CANCEL',
};

// export const stateOptions = [
//   { label: 'Neznámo', value: status.UNKNOWN },
//   { label: 'Čekání', value: status.WAIT_FOR_CONFIRM },
//   { label: 'Potvrzeno', value: status.CONFIRM },
//   { label: 'Zrušeno', value: status.CANCEL },
// ];

export const stateOptions = [
  { label: 'Neznámo', value: status.UNKNOWN },
  { label: 'K potvrzení', value: status.WAIT_FOR_CONFIRM, visibleInList: true },
  { label: 'Potvrzeno', value: status.CONFIRM, visibleInList: true },
  { label: 'Zrušeno', value: status.CANCEL, visibleInList: true },
];

export const getStatusName = (val) => {
  const finded = stateOptions.find((s) => s.value == val);
  return finded && finded.label;
};

export const getStatusColor = (val) => {
  switch (val) {
    case status.CONFIRM:
      return 'green';
    case status.WAIT_FOR_CONFIRM:
      return 'red';
    case status.CANCEL:
      return 'gray';
    default:
      return 'blue';
  }
};

import actions from './actions';
import { combineReducers } from 'redux';

const initState = { slots: [], from: null, to: null, hallId: null };

const slotReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SLOTS_SUCCESS:
      return {
        slots: action.slots,
        from: action.from,
        to: action.to,
        hallId: action.hallId,
        error: null,
      };
    case actions.SLOTS_ERROR:
      return {
        error: action.message,
      };
    default:
      return state;
  }
};

export default slotReducer;

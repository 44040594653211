const actions = {
  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_ERROR: 'USER_ERROR',
  getUser: (id) => ({
    type: actions.USER_REQUEST,
    payload: { id },
  }),
  EDITUSER_REQUEST: 'EDITUSER_REQUEST',
  EDITUSER_SUCCESS: 'EDITUSER_SUCCESS',
  EDITUSER_ERROR: 'EDITUSER_ERROR',
  editUser: (user) => ({
    type: actions.EDITUSER_REQUEST,
    payload: { user },
  }),
  CREATEUSER_REQUEST: 'CREATEUSER_REQUEST',
  CREATEUSER_SUCCESS: 'CREATEUSER_SUCCESS',
  CREATEUSER_ERROR: 'CREATEUSER_ERROR',
  createUser: (user) => ({
    type: actions.CREATEUSER_REQUEST,
    payload: { user },
  }),
  SHOW_USER_MODAL: 'SHOW_USER_MODAL',
  showUserModal: (show) => ({
    type: actions.SHOW_USER_MODAL,
    payload: { show },
  }),
  CLEAR_EDITUSER: 'CLEAR_EDITUSER',
  clearEditUser: () => ({
    type: actions.CLEAR_EDITUSER,
  }),
  CLEAR_EDITUSER_ERRORS: 'CLEAR_EDITUSER_ERRORS',
  clearEditUserErrors: () => ({
    type: actions.CLEAR_EDITUSER_ERRORS,
  }),
};
export default actions;

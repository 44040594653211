const actions = {
  CHANGEPASSWORD_REQUEST: 'CHANGEPASSWORD_REQUEST',
  CHANGEPASSWORD_SUCCESS: 'CHANGEPASSWORD_SUCCESS',
  CHANGEPASSWORD_ERROR: 'CHANGEPASSWORD_ERROR',

  changePassword: (oldPassword, newPassword, checkNewPassword) => ({
    type: actions.CHANGEPASSWORD_REQUEST,
    payload: { oldPassword, newPassword, checkNewPassword },
  }),

  CLEAR_ERROR_CHANGEPASSWORD: 'CLEAR_ERROR_CHANGEPASSWORD',
  clearChangePasswordError: () => ({
    type: actions.CLEAR_ERROR_CHANGEPASSWORD,
  }),
};
export default actions;

import actions from './actions';

const defaultUser = {
  email: '',
  firstname: '',
  funeralServiceName: '',
  id: '',
  lastname: '',
  note: '',
  roleNames: [],
  adminCeremonyHalls: [],
};

const initState = { data: defaultUser, formError: null, isVisible: false };

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.USER_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    case actions.USER_ERROR:
      return {
        ...state,
        get_error: action.message,
      };

    case actions.CREATEUSER_ERROR:
    case actions.EDITUSER_ERROR:
      console.log(action);
      if (action.error && action.error.status == 422) {
        return {
          ...state,
          formError: action.error.items,
        };
      } else {
        return state;
      }
    case actions.CREATEUSER_SUCCESS:
    case actions.EDITUSER_SUCCESS:
      return { ...state, isVisible: false, formError: null };
    case actions.SHOW_USER_MODAL:
      return {
        ...state,
        isVisible: action.payload.show,
        formError: null,
        data: defaultUser,
      };
    case actions.CLEAR_EDITUSER:
      return { ...state, data: defaultUser };
    default:
      return state;
  }
}

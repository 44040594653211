import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams, authorizedCall } from '../helpers/authHelper';
import message from '../../helpers/message';

export function* getBookingOverview() {
  yield takeEvery(actions.BOOKINGOVERVIEW_REQUEST, function* ({ payload }) {
    //const { from, to, hallId } = payload;
    yield authorizedCallParams(
      api.getBookingOverview,
      payload,
      (data) => ({
        type: actions.BOOKINGOVERVIEW_SUCCESS,
        items: data.items,
        totalItems: data.totalItems,
      }),
      (err) => ({
        type: actions.BOOKINGOVERVIEW_ERROR,
        message: err,
      })
    );
  });
}

export function* confirmBooking() {
  yield takeEvery(actions.CONFIRMBOOKING_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.confirmBooking,
      payload,
      (data) => {
        return {
          type: actions.CONFIRMBOOKING_SUCCESS,
        };
      },
      (err) => ({
        type: actions.CONFIRMBOOKING_ERROR,
        message: err,
      })
    );
  });
}

export function* cancelBooking() {
  yield takeEvery(actions.STORNOBOOKING_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.cancelBooking,
      payload,
      (data) => {
        return {
          type: actions.STORNOBOOKING_SUCCESS,
        };
      },
      (err) => ({
        type: actions.STORNOBOOKING_ERROR,
        message: err,
      })
    );
  });
}

function* refreshBookingOverview() {
  const { filter, page, sort } = yield select(
    (state) => state.BookingOverview.filter
  );
  yield put(actions.getBookingOverview(filter, page, sort));
}

export function* onCancel() {
  yield takeEvery(actions.STORNOBOOKING_SUCCESS, function* () {
    yield refreshBookingOverview();
    message.warning('Termín byl zrušen');
  });
}

export function* onConfirm() {
  yield takeEvery(actions.CONFIRMBOOKING_SUCCESS, function* () {
    yield refreshBookingOverview();
    message.success('Termín byl potvrzen');
  });
}

// resim v komponente
// function* onResetFilterState() {
//   yield takeEvery(actions.RESET_BOOKINGOVERVIEW_FILTER, function* () {
//     yield refreshBookingOverview();
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(getBookingOverview),
    fork(confirmBooking),
    fork(cancelBooking),
    fork(onConfirm),
    fork(onCancel),
    // fork(onResetFilterState)
  ]);
}

import { all } from 'redux-saga/effects';
import authSagas from './auth/saga'; // '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import hallsSaga from './halls/saga';
import hallCalendarSaga from './hallCalendar/saga';
import messagesSaga from './messages/saga';
import usersSaga from './users/saga';
import userSaga from './user/saga';
import userSettingsSaga from './userSettings/saga';
import bookingOverviewSaga from './bookingOverview/saga';
import bookingInfoSaga from './bookingInfo/saga';
import hallEditSaga from './hallEdit/saga';
import slotEditSaga from './timeSlotsEdit/saga';
import portalConfigSaga from './portalConfig/saga';

import { history } from '../app';
export { history };

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    bookingInfoSaga(),
    bookingOverviewSaga(),
    hallsSaga(),
    hallCalendarSaga(),
    hallEditSaga(),
    slotEditSaga(),
    usersSaga(),
    userSaga(),
    userSettingsSaga(),
    messagesSaga(),
    ecommerceSaga(),
    portalConfigSaga(),
  ]);
}

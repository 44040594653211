import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import { authorizedCall, authorizedCallParams } from '../helpers/authHelper';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';

export function* getHallsRequest() {
  yield takeEvery('HALLS_REQUEST', function* () {
    yield authorizedCall(
      api.getCeremonyHalls,
      (data) => ({
        type: actions.HALLS_SUCCESS,
        halls: data.ceremonyHalls,
      }),
      (err) => ({
        type: actions.HALLS_ERROR,
        message: err,
      })
    );
  });
}

export function* getHallsSuccess() {
  yield takeEvery(actions.HALLS_SUCCESS, function* (payload) {});
}

export function* getHallsError() {
  yield takeEvery(actions.HALLS_ERROR, function* () {});
}

export function* deleteHall() {
  yield takeEvery(actions.DELETE_HALL_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.deleteHall,
      payload,
      (data) => ({
        type: actions.DELETE_HALL_SUCCESS,
        data: data,
      }),
      (err) => ({
        type: actions.DELETE_HALL_ERROR,
        message: err,
      })
    );
  });
}

export function* onDeleteHallSuccess() {
  yield takeEvery(actions.DELETE_HALL_SUCCESS, function* () {
    yield put(actions.getHalls());
  });
}

export default function* rootSaga() {
  yield all([
    fork(getHallsRequest),
    fork(getHallsSuccess),
    fork(getHallsError),
    fork(deleteHall),
    fork(onDeleteHallSuccess),
  ]);
}

import antdCZ from 'antd/lib/locale-provider/cs_CZ';
import czMessages from '../locales/cs_CZ.json';

const czLang = {
  messages: {
    ...czMessages,
  },
  antd: antdCZ,
  locale: 'cs',
};
export default czLang;

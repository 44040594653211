import {
  all,
  takeEvery,
  put,
  fork,
  call,
  select,
  take,
} from 'redux-saga/effects';
import { Map } from 'immutable';
export function clearToken() {
  localStorage.removeItem('id_token');
  sessionStorage.removeItem('id_token');
}

export function getToken() {
  try {
    const idToken =
      sessionStorage.getItem('id_token') || localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function setToken(token, { wantPersist }) {
  clearToken();
  const storage = wantPersist ? localStorage : sessionStorage;
  return storage.setItem('id_token', token);
}

export function* authorizedCallParams(method, params, success, error) {
  try {
    const token = getToken().get('idToken');
    if (token) {
      const data = yield call(method, { authKey: token, ...params });
      yield put(success(data));
    }
  } catch (ex) {
    console.log(JSON.stringify(ex));
    yield put(error(ex.message, ex.data));
  }
}

export function* authorizedCall(method, success, error) {
  try {
    const token = getToken().get('idToken');
    if (token) {
      const data = yield call(method, { authKey: token });
      yield put(success(data));
    }
  } catch (ex) {
    yield put(error(ex.message, ex.data));
  }
}

import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import * as api from '../../api';
import { authorizedCallParams, authorizedCall } from '../helpers/authHelper';
import message from '../../helpers/message';

export function* getUsers() {
  yield takeEvery(actions.USERS_REQUEST, function* ({ payload }) {
    //const { from, to, hallId } = payload;
    yield authorizedCallParams(
      api.getUsers,
      payload,
      (data) => ({
        type: actions.USERS_SUCCESS,
        items: data.items,
        totalItems: data.totalItems,
      }),
      (err) => ({
        type: actions.USERS_ERROR,
        message: err,
      })
    );
  });
}

export function* getAllRoles() {
  yield takeEvery(actions.ROLES_REQUEST, function* () {
    //const { from, to, hallId } = payload;
    yield authorizedCall(
      api.getAllRoles,
      (data) => {
        return {
          type: actions.ROLES_SUCCESS,
          roles: data,
        };
      },
      (err) => ({
        type: actions.ROLES_ERROR,
        message: err,
      })
    );
  });
}

export function* enableUser() {
  yield takeEvery(actions.ENABLEUSER_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.enableUser,
      payload,
      (data) => {
        return {
          type: actions.ENABLEUSER_SUCCESS,
        };
      },
      (err) => ({
        type: actions.ENABLEUSER_ERROR,
        message: err,
      })
    );
  });
}

export function* disableUser() {
  yield takeEvery(actions.DISABLEUSER_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.disableUser,
      payload,
      (data) => {
        return {
          type: actions.DISABLEUSER_SUCCESS,
        };
      },
      (err) => ({
        type: actions.DISABLEUSER_ERROR,
        message: err,
      })
    );
  });
}

export function* deleteUser() {
  yield takeEvery(actions.DELETEUSER_REQUEST, function* ({ payload }) {
    yield authorizedCallParams(
      api.deleteUser,
      payload,
      (data) => {
        return {
          type: actions.DELETEUSER_SUCCESS,
        };
      },
      (err) => ({
        type: actions.DELETEUSER_ERROR,
        message: err,
      })
    );
  });
}

function* refreshUsers() {
  const { filter, page, sort } = yield select((state) => state.Users.filter);
  yield put(actions.getUsers(filter, page, sort));
}

export function* onDisableUser() {
  yield takeEvery(actions.DISABLEUSER_SUCCESS, function* () {
    yield refreshUsers();
  });
}

export function* onEnableUser() {
  yield takeEvery(actions.ENABLEUSER_SUCCESS, function* () {
    yield refreshUsers();
  });
}

export function* onDeleteUser() {
  yield takeEvery(actions.DELETEUSER_SUCCESS, function* () {
    yield refreshUsers();
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUsers),
    fork(getAllRoles),
    fork(enableUser),
    fork(disableUser),
    fork(deleteUser),
    fork(onDisableUser),
    fork(onEnableUser),
    fork(onDeleteUser),
  ]);
}

const actions = {
  SLOTS_REQUEST: 'SLOTS_REQUEST',
  SLOTS_SUCCESS: 'SLOTS_SUCCESS',
  SLOTS_ERROR: 'SLOTS_ERROR',
  getSlots: (hallId, from, to) => ({
    type: actions.SLOTS_REQUEST,
    payload: { hallId, from, to },
  }),

  EDITSLOT_REQUEST: 'EDITSLOT_REQUEST',
  EDITSLOT_SUCCESS: 'EDITSLOT_SUCCESS',
  EDITSLOT_ERROR: 'EDITSLOT_ERROR',
  editSlot: (data) => ({
    type: actions.EDITSLOT_REQUEST,
    payload: { data },
  }),

  CREATESLOT_REQUEST: 'CREATESLOT_REQUEST',
  CREATESLOT_SUCCESS: 'CREATESLOT_SUCCESS',
  CREATESLOT_ERROR: 'CREATESLOT_ERROR',
  createSlot: (hallId, data) => ({
    type: actions.CREATESLOT_REQUEST,
    payload: { hallId, data },
  }),

  DELETESLOT_REQUEST: 'DELETESLOT_REQUEST',
  DELETESLOT_SUCCESS: 'DELETESLOT_SUCCESS',
  DELETESLOT_ERROR: 'DELETESLOT_ERROR',
  deleteSlot: (data,start) => ({
    type: actions.DELETESLOT_REQUEST,
    payload: { data,start },
  }),

  DELETESLOTALL_REQUEST: 'DELETESLOTALL_REQUEST',
  DELETESLOTALL_SUCCESS: 'DELETESLOTALL_SUCCESS',
  DELETESLOTALL_ERROR: 'DELETESLOTALL_ERROR',
  deleteSlotAll: (data,start) => ({
    type: actions.DELETESLOTALL_REQUEST,
    payload: { data,start },
  }),
};
export default actions;

import message from '../../helpers/message';

import {
  all,
  takeEvery,
  put,
  fork,
  call,
  select,
  take,
} from 'redux-saga/effects';

export function* takexSaga(pattern) {
  let action;
  while (true) {
    action = yield take('*');
    //   console.log('takex', action.type);
    if (pattern.test(action.type)) {
      // console.log('takex:match', action.type);
      break;
    }
    //   console.log('takex:ignore', action.type);
  }
  return action;
}

export function takex(pattern) {
  return call(takexSaga, pattern);
}

export function* handleErrors() {
  while (true) {
    const action = yield takex(/.*_ERROR$/g);
    message.error('' + action.message);
  }
}

export default function* rootSaga() {
  yield all([fork(handleErrors)]);
}
